var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "form-wrapper",
      attrs: {
        "auto-complete": "on",
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "200px",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Name", prop: "name" } },
        [
          _c("el-input", {
            attrs: { name: "name", type: "text", "auto-complete": "off" },
            on: { input: _vm.generateReference },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Reference", prop: "ref" } },
        [
          _c("el-input", {
            attrs: {
              name: "ref",
              type: "text",
              maxlength: "3",
              "auto-complete": "off",
              placeholder: "XXX",
            },
            model: {
              value: _vm.form.ref,
              callback: function ($$v) {
                _vm.$set(_vm.form, "ref", $$v)
              },
              expression: "form.ref",
            },
          }),
        ],
        1
      ),
      !_vm.client
        ? _c(
            "el-form-item",
            { attrs: { label: "Client" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "Assign to an existing client",
                    "value-key": "id",
                  },
                  on: { change: _vm.assignClient },
                  model: {
                    value: _vm.form.client,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "client", $$v)
                    },
                    expression: "form.client",
                  },
                },
                _vm._l(_vm.clients, function (c) {
                  return _c("el-option", {
                    key: c.id,
                    attrs: { label: c.name, value: c },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-button",
        { attrs: { disabled: _vm.creating }, on: { click: _vm.handleSubmit } },
        [_vm._v(" " + _vm._s(_vm.client ? "Create and link" : "Create") + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }